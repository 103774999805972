import { Auth } from "@aws-amplify/auth";
import type { AxiosRequestConfig } from "axios";
import enhancedAxios from "./requestUtil";

const _baseUri = "/api/auth";

declare var grecaptcha: any;

const _join = (base: string, route: string) =>
  route ? `${base}/${route}` : base;
Auth.configure({
  region: "us-east-2",
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  authenticationFlowType: "CUSTOM_AUTH",
});
export class AuthClient {
  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get<T>(_join(_baseUri, route), args);
    return response.data;
  }

  async post<T, TData>(
    route: string,
    data: TData,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.post(
      _join(_baseUri, route),
      data,
      args,
    );
    return response.data;
  }

  async userInfo(): Promise<any> {
    return await Auth.currentAuthenticatedUser();
  }

  async loginWithWallet(walletId: string): Promise<any> {
    return await Auth.signIn(walletId, undefined, { signInType: "Wallet" });
  }

  async logout(): Promise<any> {
    return await Auth.signOut();
  }

  async finalizeWalletLogin(accountInfo: any, signature: string): Promise<any> {
    return await Auth.sendCustomChallengeAnswer(accountInfo, signature);
  }

  async register(
    walletId: string,
    message: string,
    signature: string,
  ): Promise<any> {
    const token = await new Promise<string>((resolve, reject) => {
      grecaptcha.ready(() => {
        grecaptcha
          .execute(process.env.NEXT_PUBLIC_RECAPTCHA_KEY, { action: "submit" })
          .then(resolve, reject);
      });
    });
    return await Auth.signUp({
      username: walletId,
      password: window.crypto.randomUUID(),
      attributes: {
        ["custom:accountType"]: "Wallet",
      },
      clientMetadata: {
        message,
        signature,
        token,
      },
      autoSignIn: {
        enabled: true,
      },
    });
  }
}
