import type { AxiosRequestConfig } from "axios";
import type { AuthTokenClient } from "model/auth/AuthTokenClient";

export class BaseAuthTokenClient implements AuthTokenClient {
  protected authToken: string | null | undefined = null;
  protected getAuthToken(defaultArgs?: AxiosRequestConfig) {
    if (!this.authToken) return defaultArgs;
    if (!defaultArgs) defaultArgs = {};
    defaultArgs.headers = defaultArgs.headers || {};
    defaultArgs.headers.Authorization = `Bearer ${this.authToken}`;
    return defaultArgs;
  }
  setAuthToken(token: string | null | undefined): void {
    this.authToken = token;
  }
}
