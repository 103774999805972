import { bs58 } from "@project-serum/anchor/dist/cjs/utils/bytes";
import { useWallet } from "@solana/wallet-adapter-react";
import enhancedAxios from "clients/requestUtil";
import { LoginWithWalletRequestModel } from "model/auth/LoginModel";
import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from "react";
import useClient from "src/hooks/useClient";
import { getAuthTokenHeader } from "src/utils/auth/getAuthTokenHeader";
import useSWR from "swr";

export interface AuthContextProps {
  userId: string | null;
  isLoading: boolean;
  isLoggedIn: boolean;
  isWalletConnected: boolean;
  token?: string;
  signIn: () => void;
}

const isBrowser = typeof window !== "undefined";

export const AuthContext = createContext<AuthContextProps>({
  userId: null,
  isLoggedIn: false,
  isLoading: false,
  isWalletConnected: false,
  signIn: () => {},
});

const encoder = new TextEncoder();

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const { publicKey, signMessage, connected } = useWallet();
  const client = useClient("auth");
  const [loginRequest, setLoginRequest] = useState<
    | (Pick<LoginWithWalletRequestModel, "signature" | "walletId"> & {
        accountInfo: any;
      })
    | null
  >(null);
  const userInfo = useSWR(["user-info"], async () => {
    try {
      return await client.userInfo();
    } catch {
      return null;
    }
  });

  const login = useSWR(
    !loginRequest ? null : ["auth", publicKey?.toBase58()],
    async () => {
      const result = await client.finalizeWalletLogin(
        loginRequest!.accountInfo,
        loginRequest!.signature,
      );
      userInfo.mutate();
      return result;
    },
  );

  const signIn = useMemo(
    () => () => {
      if (!connected || !signMessage || !isBrowser) return;
      const run = async () => {
        try {
          const accountInfo = await client.loginWithWallet(
            publicKey!.toBase58(),
          );
          const message = `Would you like to sign in with your wallet?  \r\n\r\nRequestId: ${accountInfo?.challengeParam?.requestId}`;
          const result = await signMessage(encoder.encode(message));
          setLoginRequest({
            signature: bs58.encode(result),
            walletId: publicKey!.toBase58(),
            accountInfo,
          });
        } catch (e: any) {
          if (e?.message === "Incorrect username or password.") {
            const message = `Would you like to register with your wallet?  \r\n\r\nRequestId: ${window.crypto.randomUUID()}`;
            const result = await signMessage(encoder.encode(message));
            await client.register(
              publicKey!.toBase58(),
              message,
              bs58.encode(result),
            );
            try {
              const accountInfo = await client.loginWithWallet(
                publicKey!.toBase58(),
              );
              const message = `Would you like to sign in with your wallet?  \r\n\r\nRequestId: ${accountInfo?.challengeParam?.requestId}`;
              const result = await signMessage(encoder.encode(message));
              setLoginRequest({
                signature: bs58.encode(result),
                walletId: publicKey!.toBase58(),
                accountInfo,
              });
            } catch {}
          }
        }
      };
      run();
    },
    [connected, publicKey?.toBase58()],
  );

  const token = userInfo.data?.signInUserSession?.idToken?.jwtToken;

  useEffect(() => {
    if (!connected || token) return;
    signIn();
  }, [connected]);

  useEffect(() => {
    if (!token) return;
    enhancedAxios
      .get("/api/auth/ping", getAuthTokenHeader(token))
      .then(() => {})
      .catch(() => {});
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        isLoggedIn: !!userInfo.data,
        isLoading: !!loginRequest && !login.data && !login.error,
        userId: userInfo.data?.attributes?.sub || null,
        isWalletConnected: connected,
        signIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
