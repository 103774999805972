import type { AxiosRequestConfig } from "axios";
import type {
  BasicAggregationResult,
  KeyStatsResult,
  SeriesY,
  TimeRanges,
} from "../model/aggregations";
import type { SolanaService } from "../model/aggregations/solana/service";
import enhancedAxios from "./requestUtil";

interface SolanaClientQueryParams {
  params: {
    timeRangeFilter: TimeRanges;
    protocolFilter: string;
    includeFailed?: boolean;
  };
}

export class SolanaClient implements SolanaService {
  keyStats(params?: any): Promise<KeyStatsResult> {
    return this.get<KeyStatsResult>("/keyStats", params);
  }

  dailyFirstSigners(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "/dailyFirstSigners",
      params,
    );
  }

  ecosystem(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "/ecosystem",
      params,
    );
  }

  uniqueBuyers(params?: SolanaClientQueryParams): Promise<BasicAggregationResult<string, number>> {
    return this.get<BasicAggregationResult<string, number>>(
      "/uniqueBuyers",
      params,
    );
  }

  dailyActiveUsers(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "/dailyActiveUsers",
      params,
    );
  }

  averageAmountTransferred(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "averageAmountTransferred",
      params,
    );
  }

  averageWalletBalanceBuyers(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "averageWalletBalanceBuyers",
      params,
    );
  }

  averageWalletBalanceSellers(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "averageWalletBalanceSellers",
      params,
    );
  }

  uniqueSellers(params?: SolanaClientQueryParams): Promise<BasicAggregationResult<string, number>> {
    return this.get<BasicAggregationResult<string, number>>("uniqueSellers", params);
  }

  volume(params?: SolanaClientQueryParams): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>("volume", params);
  }

  totalValueMovedOnChain(
    params?: SolanaClientQueryParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "totalValueMovedOnChain",
      params,
    );
  }

  async get<T>(route: string, params: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get<T>(
      `/api/aggregations/solana/${route}`,
      params,
    );
    return response.data;
  }
}
