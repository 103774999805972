import { useContext, useEffect, useState } from "react";
import { DynamoConfigContext, DynamoEnvKeys } from "src/context/configContext";

const DEFAULT_NODE = "https://try-rpc.mainnet.solana.blockdaemon.tech";

export const useRpcNode = (): string => {
  let hostname: string | undefined;
  if (typeof window !== "undefined") {
    hostname = window.location.origin;
  }

  const [endpoint, setCurrentEndpoint] = useState<string>(DEFAULT_NODE);
  const configContext = useContext(DynamoConfigContext);

  useEffect(() => {
    const config = configContext?.config;
    const rpcValue = config?.find(
      (configEle) => configEle.key.S === DynamoEnvKeys.RPC_NODES,
    );
    const useProxyValue = config?.find(
      (configEle) => configEle.key.S === DynamoEnvKeys.USE_RPC_PROXY,
    );

    const endpoints = rpcValue?.value.SS;
    const useProxyRpc = useProxyValue?.value.BOOL;

    if (useProxyRpc && hostname) {
      setCurrentEndpoint(`${hostname}/api/rpc`);
    } else if (!useProxyRpc && rpcValue && endpoints) {
      setCurrentEndpoint(endpoints[0] ?? DEFAULT_NODE);
    }

  }, [configContext, hostname]);

  return endpoint;
};
