import type { AxiosRequestConfig } from "axios";
import type { ApiKeyModel, OveragePreference } from "model/apiKey/ApiKeyModel";
import { BaseAuthTokenClient } from "./baseAuthTokenClient";
import enhancedAxios from "./requestUtil";
import { number } from "zod";

const _baseUri = "/api/apiKeys";

const _join = (base: string, route: string) =>
  route ? `${base}/${route}` : base;

export class ApiKeysClient extends BaseAuthTokenClient {
  private async _get<T>(
    route: string,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.get(
      _join(_baseUri, route),
      this.getAuthToken(args),
    );
    return response.data;
  }
  private async _post<T, TData>(
    route: string,
    data: TData,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.post(
      _join(_baseUri, route),
      data,
      this.getAuthToken(args),
    );
    return response.data;
  }

  private async _put<T, D>(
    route: string,
    data: D,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.put(
      _join(_baseUri, route),
      data,
      this.getAuthToken(args),
    );
    return response.data;
  }

  async getAll(): Promise<ApiKeyModel[]> {
    return await this._get("");
  }

  async updateOveragePreference(args: {
    key: string;
    preference: OveragePreference;
    customLimit?: number;
  }): Promise<boolean> {
    const data = await this._put("prefs", args);
    return (data as any).success ?? false;
  }
}
