export enum CONFIGURED_WALLETAUTH_ENDPOINTS {
  // LOGZ A USER IN WITH DER WALLET
  WALLET_LOGIN = "wallet-login",
  // CREATES AN ACCOUNT FOR USAR WITH BUTTON
  CREATE_ACCOUNT = "wallet-create-account",
  // verfies the authKey we gave them
  VERIFY_KEY = "verify-key",
}

export interface GenerateUserInput {
  country: string;
  city: string;
  wallet_id: string;
}

export interface VerifyKeyResponse {
  //weather this IZA valid key
  verified: boolean;
}

export interface LoginOrSignUpResponse {
  verified: boolean;
  secretKey?: string;
}

export interface WalletAuthService {
  /**
   * Does this wallet exist?
   * @param wallet_id
   */
  walletExists?(walletId: string): Promise<boolean>;

  /**
   * Verifies that a secret Key belongs to a wallet
   * @param secretKey
   * @returns verifyResponse JSON with boolean {Verified} inside
   */
  verifyKey(
    secretKey: string,
    WALLET_SALT?: string,
  ): Promise<VerifyKeyResponse>;

  /**
   * Takes in location info + public key and logs user in
   * or signs them up depending on if they already have an account
   * @param params
   * @returns loginResponse with whether they were verified and their verificationKey
   */
  loginOrSignUp(
    params: GenerateUserInput,
    WALLET_SALT?: string,
  ): Promise<LoginOrSignUpResponse>;
}
